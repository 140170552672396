<template>
  <v-container class="pa-0" style="max-width: none;">
    <v-card
      flat
      height="calc(100vh - 64px - 56px - 64px - 32px)"
      tile
      width="100%"
    >

      <v-toolbar
        color="transparent"
        flat
      >
        <v-toolbar-title>Pont-bascule</v-toolbar-title>

        <v-spacer></v-spacer>

        <ConnectionStatus />
      </v-toolbar>

      <v-card-text class="pt-0">
        <v-select
          @change="onChange"
          v-model="selected"
          color="lightgrey"
          flat
          :items="sites"
          item-text="name"
          item-value="id"
          label="Site"
          prepend-icon="mdi-home-silo"
          :rounded="false"

        ></v-select>
      </v-card-text>

      <v-sparkline
        color="#EEE"
        :value="history"
        :smooth="10"
        :padding="5"
        :line-width="2"
        stroke-linecap="round"
        :fill="false"
        type="trend"
        :auto-line-width="false"
        auto-draw
        style="position: absolute; bottom: 0; left: 0;"
      >
      </v-sparkline>

      <v-sheet :color="backgroundColor" class="centered-sheet pa-6" width="100%">
        <span class="text-h1 font-weight-bold">{{ weight }}</span>
        <span class="text-h2">&nbsp;kg</span>
      </v-sheet>

      <!--<v-card-text class="pt-0">
        <v-textarea
          clearable
          dense
          disabled
          flat
          full-width
          hide-details
          label="Historique"
          outlined
          readonly
          rows="25"
          :value="weightHistory"
        ></v-textarea>
      </v-card-text>-->

    </v-card>

  </v-container>
</template>

<script>
import { useMqttStore } from '@/stores/mqtt';
import ConnectionStatus from '@/components/Mqtt/ConnectionStatus';

export default {
  setup() {
    const mqttStore = useMqttStore();
    return {
      mqttStore
    };
  },

  components: {
    ConnectionStatus
  },

  mounted: function() {
    this.mqttStore.init();

    //await this.mqttStore.unsubscribe(); // @todo should unsubscribe all topics

    this.changeSite(this.selected);
  },

  computed: {
    backgroundColor: function() {
      if (this.weight > 44000) {
        return'red lighten-3';
      } else if(this.weight > 43000 && this.weight < 43500) {
        return'orange lighten-3';
      } else if(this.weight >= 43500 && this.weight <= 44000) {
        return'green lighten-3';
      } else {
        return'transparent';
      }
    }
  },

  watch: {
    'mqttStore.topicData': {
      handler: function(newVal, oldVal) {
        oldVal;

        if (typeof newVal.weight === 'undefined') {
          this.weight = '_____';
          return;
        }

        this.weight = newVal.weight;
        this.lastUpdate = newVal.time;

        const weight = +this.weight;

        this.weightHistory = this.weightHistory + '\n' + weight;

        if (!isNaN(weight)) {
          // ensure a maximum number of historical data
          if (this.history.length >= 50) {
            this.history.shift();
          }

          this.history.push(weight);
        }
      },
      deep: true,
      immediate: true
    }
  },

  data: function() {
    return {
      history: [0],
      previousIdx: null,
      previousSite: null,
      selected: 146,
      sites: [
        { id: 126, name: 'Anais' },
        { id: 144, name: 'Angliers' },
        { id: 116, name: 'Annepont' },
        { id: 102, name: 'Aulnay' },
        { id: 103, name: 'Bel Air' },
        { id: 115, name: 'Bignay' },
        { id: 132, name: 'Boutrit' },
        { id: 134, name: 'Breuil' },
        { id: 147, name: 'Choupeau' },
        { id: 137, name: 'Clussais' },
        { id: 142, name: 'Dompierre' },
        { id: 1422, name: 'Dompierre 2' },
        { id: 117, name: 'Fontenet' },
        { id: 113, name: 'La Chapelle' },
        { id: 128, name: 'La Jarne' },
        { id: 146, name: 'La Rochelle' },
        { id: 136, name: 'Loiré' },
        { id: 108, name: 'Marans' },
        { id: 119, name: 'Mazières' },
        { id: 148, name: 'Nieul' },
        { id: 122, name: 'Paillé' },
        { id: 124, name: 'Silaunis' },
        { id: 123, name: 'Silovert' },
        { id: 129, name: 'St Coutant' },
        { id: 130, name: 'St Georges' },
        { id: 131, name: 'St Germain' },
        { id: 106, name: 'St Hilaire' },
        { id: 133, name: 'St Médard' },
        { id: 143, name: 'St Ouen' },
        { id: 145, name: 'St Sauveur' },
        { id: 114, name: 'St Savinien' },
        { id: 125, name: 'St Vivien' },
        { id: 109, name: 'Ste Même' },
        { id: 135, name: 'Surgères' },
        { id: 121, name: 'Triou' },
        { id: 120, name: 'Vilaine' },
        { id: 127, name: 'Virson' },
        { id: 105, name: 'ZI' },
      ],
      weight: '_____',
      weightHistory: ''
    };
  },

  methods: {
    onChange: function(siteKey) {
      this.changeSite(siteKey);
    },

    changeSite: function(siteKey) {
      // @todo handle multi weight-scale differently
      let idx = 1;
      let site = siteKey;
      if (siteKey === 1422) {
        site = 142;
        idx = 2;
      }

      if (this.previousSite) {
        const previousTopic = 'ta/' + this.previousSite  + '/weight-scales/' + this.previousIdx;
        this.mqttStore.unsubscribe(previousTopic);
      }

      // initialize history
      this.history = [0];

      // subscription
      const topic = 'ta/' + site  + '/weight-scales/' + idx;
      this.mqttStore.subscribe(topic);

      // store the site key so we can unsubscribe on the next change
      this.previousSite = siteKey;
      this.previousIdx = idx;

      // store as a user preference
      localStorage.setItem('userPreferences', JSON.stringify({ weight_scale_site: siteKey }));
    }
  },

  created() {
    const preferences = localStorage.getItem('userPreferences');
    if (preferences) {
      this.selected = JSON.parse(preferences).weight_scale_site;
    }

    /*this.interval = setInterval(() => {
      this.cacheKey = +new Date();
    }, 10 * 1000);*/
  },

  /*destroyed() {
    clearInterval(this.interval);
  }*/
};
</script>

<style scoped>
.fill-height {
  height: calc(100vh - 64px - 56px); /* header + toolbar */
}
.centered-sheet {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
</style>